import React from 'react'
import"./tispo.css"
export const Titlesponser = () => {
  return (
    <div className="tilespo">
        <div className="bio">
        <p class="text-moving">Title Sponsor: Announcing Soon</p>
        </div>
    </div>
  )
}
