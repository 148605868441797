const Schedule = [
    {
        place: {
            "Big Auditorium": {
                day1: {
                    eq: "09:00-10:00",
                    ez: "09:00-10:00",
                    ew: "09:00-10:00",


                },
                day2: {
                    eq: "09:00-10:01",
                    ev: "09:00-10:00",
                    ep: "09:00-10:00",

                },
                day3: {
                    eq: "09:00-10:00",
                    e2: "09:00-10:00",
                    ev: "09:00-10:00",

                }
            }
        }

    },
    {
        place: {
            "Amphitheatre": {
                day1: {
                    "Mgt Fest Events": "11:00-17:00",
                },
                day2: {
                    "Gym EVENT/Closing Ceremoney)": "09:00-18:30",
                },
                day3: {
                    "Prop Knight[Tech Event]": "13:00-16:00",
                }
            }
        }
    },
    {
        place: {
            "Amphitheatre": {
                day1: {
                    "Mgt Fest Events": "11:00-17:00",
                },
                day2: {
                    "Gym EVENT/Closing Ceremoney)": "09:00-18:30",
                },
                day3: {
                    "Prop Knight[Tech Event]": "13:00-16:00",
                }
            }
        }
    },
    {
        place: {
            "R Block Garden": {
                day1: {
                    "Squid": "11:00-15:00",
                },
                day2: {
                    "no event": "-----------",
                },
                day3: {
                    "Real Life Battle Ground": "10:00-16:00",
                }
            }
        }
    },
    {
        place: {
            "Small Auditorium": {
                day1: {
                    "MBA Event": "11:00-17:00",
                },
                day2: {
                    "no event": "-----------",
                },
                day3: {
                    "no event": "-----------",
                }
            }
        }
    },
    {
        place: {
            "Main Stage": {
                day1: {
                    "Accord": "18:30-21:30",
                },
                day2: {
                    "Melange(Fashion Show)/Medi-Caps Got Talent": "09:00-18:30",

                },
                day3: {
                    "no event": "-----------",
                }
            }
        }
    },
    {
        place: {
            "Block V": {
                day1: {
                    "Quizziteria (Seminar Hall 221)": "10:00-12:00",
                },
                day2: {
                    "Google it (Room no 004)": "10:30-13:30",
                },
                day3: {
                    "Smash Carts (Lab number 002)": "13:30-16:00",
                    "Fifa Tournament Online (Lab number 016)": "10:00-14:00",
                }
            }
        }
    },
    {
        place: {
            "Block B": {
                day1: {
                    "Robotic Events": "10:00-13:30",
                },
                day2: {
                    "Robotic Events": "10:00-13:30",
                },
                day3: {
                    "no event": "-----------",
                }
            }
        }
    },
    {
        place: {
            "Block C": {
                day1: {
                    "Campus Combat Valorant Edition": "10:30-12:00",
                    "Chess": "10:30-12:00",
                },
                day2: {
                    "Gym EVENT/Closing Ceremoney)": "09:00-18:30",
                },
                day3: {
                    "Prop Knight[Tech Event]": "13:00-16:00",
                }
            }
        }
    },
    {
        place: {
            "Block Q": {
                day1: {
                    "The Squid Game": "11:00-13:00",
                    "Flirt With Knives": "11:00-14:00",
                    "Jo Dikhta Hai Vo Bikta Hai": "10:00-16:00",
                    "Standup Comedy": "13:00-16:00",
                    "Snake and Ladder": "10:00-13:00",
                    "Ringozz (Room no: 001)": "11:00-13:00",
                    "HunarBazz  (Room no: 004)": "10:00-12:00",
                    "Anime-Zing Quizz ": "13:00-16:00",
                    "Feel It ReeL It ": "11:00-13:00",

                },
                day2: {
                    "Feel It ReeL It ": "10:00-16:00",
                    "Web Clone Competition ": "13:30-16:00",
                    "Short Film Competition ": "10:00-16:00",
                    "Golgappa Challenge ": "12:00-14:00",
                    "T-Shirt with Face Painting ": "10:00-12:00",
                    "Bollywood Ke DrameBazz ": "12:00-16:00",
                    "Rangoli Making ": "12:00-14:00",
                    "BollyGeeks ": "12:00-14:00",
                    "FuntakShiri ": "12:00-14:00",
                    "Best Future Ceo ": "12:00-14:00",
                    "Campus Beautification ": "12:00-16:00",
                    "Stock Maestro ": "10:00-16:00",
                    "Jo Dikhta Hai Vo Bikta Hai ": "10:00-16:00",
                    "Bollywood Business Quiz ": "13:00-13:30",



                },
                day3: {
                    "Feel It ReeL It ": "10:00-16:00",
                }
            }
        }
    },
    {
        place: {
            "Block M": {
                day1: {
                    "Blast The Ballon ": "11:00-16:00",
                    "Risky Handling": "10:00-12:30",
                },
                day2: {
                    "no event": "-----------",
                },
                day3: {
                    "no event": "-----------",
                }
            }
        }
    },
    {
        place: {
            "Multi Purpose Hall": {
                day1: {
                    "Table Tennis/Carrom [3 April]": "09:00-17:00",
                    "Chess [3 April]": "09:00-17:00",

                    "Table Tennis/Carrom [4 April]": "09:00-17:00",
                    "Chess [4 April]": "09:00-17:00",

                    "Table Tennis/Carrom [5 April]": "09:00-17:00",
                    "Chess [5 April]": "09:00-17:00",

                    "Table Tennis/Carrom [6 April]": "09:00-17:00",
                    "Chess [6 April]": "09:00-17:00",


                },
                day2: {
                    "Table Tennis/Carrom [7 April]": "09:00-17:00",
                    "Chess [7 April]": "09:00-17:00",


                },
                day3: {

                    "no event": "-----------",
                }
            }
        }
    },
    {
        place: {
            "Kho-Kho Ground": {
                day1: {
                    "Shot Put [4 April]": "09:00-17:00",
                    "Shot Put [5 April]": "09:00-17:00",

                    "Shot Put [6 April]": "09:00-17:00",
                },
                day2: {
                    "Shot Put [7 April]": "09:00-17:00",

                },
                day3: {
                    "no event": "-----------",
                }
            }
        }
    }, {
        place: {
            "Amphitheatre": {
                day1: {
                    "Badminton [3 April]": "09:00-17:00",
                    "Badminton [4 April]": "09:00-17:00",
                    "Badminton [5 April]": "09:00-17:00",
                    "Badminton [6 April]": "09:00-17:00",

                },
                day2: {
                    "Badminton [7 April]": "09:00-17:00",

                },
                day3: {
                    "Badminton [8 April]": "09:00-12:00",

                }
            }
        }
    }, {
        place: {
            "Bus Parking Lawn": {
                day1: {
                    "Mini Soccer[2 April]": "09:00-17:00",
                    "Mini Soccer[2 April]": "09:00-17:00",
                    "Mini Soccer[2 April]": "09:00-17:00",
                    "Mini Soccer[2 April]": "09:00-17:00",
                    "Mini Soccer[2 April]": "09:00-17:00",

                },
                day2: {
                    "Gym EVENT/Closing Ceremoney)": "09:00-18:30",
                },
                day3: {
                    "Prop Knight[Tech Event]": "13:00-16:00",
                }
            }
        }
    }, {
        place: {
            "Amphitheatre": {
                day1: {
                    "Mgt Fest Events": "11:00-17:00",
                },
                day2: {
                    "Gym EVENT/Closing Ceremoney)": "09:00-18:30",
                },
                day3: {
                    "Prop Knight[Tech Event]": "13:00-16:00",
                }
            }
        }
    }, {
        place: {
            "Amphitheatre": {
                day1: {
                    "Mgt Fest Events": "11:00-17:00",
                },
                day2: {
                    "Gym EVENT/Closing Ceremoney)": "09:00-18:30",
                },
                day3: {
                    "Prop Knight[Tech Event]": "13:00-16:00",
                }
            }
        }
    }, {
        place: {
            "Amphitheatre": {
                day1: {
                    "Mgt Fest Events": "11:00-17:00",
                },
                day2: {
                    "Gym EVENT/Closing Ceremoney)": "09:00-18:30",
                },
                day3: {
                    "Prop Knight[Tech Event]": "13:00-16:00",
                }
            }
        }
    },

]
export default Schedule;