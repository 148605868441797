import React from 'react'
import"./developer.css"
import { Teams } from './Our Team/Team'
export const Developer = () => {
  return (
    
    <div className="deve">
<div className="our-team">
        <h2 className="team-name" >Our Developers</h2>
       <Teams/>
      </div>
    </div>
  )
}
